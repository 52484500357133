<template>
	<mainBox mClass='lgMbox gnlist' v-loading='httploading'>
		<div class='topBox'>
			<div class='h_name'>功能列表</div>
			<div class="legoBtns">
				<el-button class="btBlue" @click='resetDatas'> 取消</el-button>
				<el-button type="primary" @click='saveCheckDatas'> 保存</el-button>
			</div>
		</div>
		<div class="ctBox2">
			<div class="itemBox" v-for="(node,dex) in treeData" :key='dex'>
				<div class="typeName">
					<el-checkbox :indeterminate="node.isIndeterminate" v-model="node.allCheck" @change="itNodeAllChange(node)">{{node.functionTypeName}}</el-checkbox>
				</div>
			  	<div class="line40">
			  		<div class="txtip" v-if="!node.functionList||node.functionList.length<1">无对应数据</div>
			  		<el-checkbox-group v-model="node.checkedArrs " @change="itNodeChange(node)">
			    		<el-checkbox class='w188' v-for="(it,i) in node.functionList" :label="it.functionId" :key="i">{{it.functionName}}</el-checkbox>
			  		</el-checkbox-group>
			  	</div>
			</div>
		</div>
	</mainBox>
</template>

<script>
	export default {
		data() {
			return {
				treeData:[],
				httploading:false,
			};
		},
		created() {
			this.initDatasArr();
		},
		methods: {
			resetDatas(){
				this.$confirm('是否放弃已操作数据，进行刷新？', '提示').then(() => {
					this.initDatasArr();
				}).catch(() => {});
			},
			async initDatasArr(){
				//获取数据
				let res = await this.ApiHttp('/legoBuilder/miniprogramFunction/list');
				if(res){
					res.map(it=>{
						it.checkedArrs = [];
						it.allCheck = false;
						it.isIndeterminate = false;
						if(it.functionList.length>0){
							let ckArr=[];
							it.functionList.map(it=>{
								if(it.functionStatus=='ENABLE') ckArr.push(it.functionId);
							});
							it.checkedArrs=ckArr;
							this.itNodeChange(it)
						}
					})
					this.treeData=res||[];
//					console.log('initDatasArr',JSON.parse(JSON.stringify(this.treeData)))
				}
			},
			itNodeAllChange(_n) {
			 	const idArrs = _n.functionList.map(item => item.functionId);
			 	_n.checkedArrs = _n.allCheck ? idArrs : [];
			 	_n.isIndeterminate = false;
		      },
		      itNodeChange(_n) {
		      	let options=JSON.parse(JSON.stringify(_n.functionList));
		        let checkedCount = _n.checkedArrs.length;
		        console.log('2222',_n)
		        _n.allCheck = checkedCount === options.length;
		        _n.isIndeterminate = checkedCount > 0 && checkedCount < options.length;
		      },
			async saveCheckDatas(){
				
				let itObj={};
				this.treeData.map(it=>{
					itObj[it.functionType]=it.checkedArrs;
				})
//				console.log('saveCheckDatas',JSON.parse(JSON.stringify(itObj)))
				let res = await this.ApiHttp('/legoBuilder/miniprogramFunction/batchUpdateEnableStatus',itObj,'post');
				if(res){
					this.initDatasArr();
					this.$message({type:'success',message:'操作成功'});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.gnlist{
		.topBox{
			line-height: 54px;
			border-bottom: 1px solid #F1F4F6;
			position: relative;
			.h_name{
				font-weight: 600;
				font-size: 14px;
				color: #252D3D;
			}
			.legoBtns{
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.ctBox2{
			margin-top: 14px;
			.itemBox{
				min-height: 20vh;
				border-radius: 4px;
				margin-bottom: 10px;
				.typeName{
					height: 40px;
					line-height: 40px;
					padding: 0 22px;
					background: rgb(238,240,244);
					margin-bottom: 10px;
				}
				.line40{
					line-height: 40px;
				    position: relative;
				    width: calc(100% + 20px);
				    left: -10px;
					.w188{
					    padding: 0 22px;
					    width: calc(20% - 20px);
					    margin: 10px;
					    border: 1px solid #E4E7EB;
					}
				}
				.txtip{
					font-size: 12px;
					line-height: 40px;
					color: #909399;
					padding-left: 32px;
				}
			}
			
		}
		
	}
</style>
<style type="text/css" lang="scss">
	.gnlist .m-body{
			padding-top: 0 !important;
		}
		.itemBox{
			.typeName .el-checkbox__label{
				color: #252D3D !important;
				font-weight: 600;
			}
			.el-checkbox__inner{
				background-color:#E6EAF5;
			}
			.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
				background-color:#2A61FF
			}
			.el-checkbox__label{
				color: #5D687C !important;
			}
		}
</style>